import React from "react";
import {Link} from "react-router-dom";

const AuthError = () => {

    return (

        (<>
            <div className="futuristic-error-notification">
                <span
                    className="futuristic-error-message">Your session has expired. Please return to the login screen
                    <div>
                    <Link
                        className="futuristic-error-message"
                        to="/login">
                      Click here to navigate to the login page
                    </Link></div></span>
            </div>
        </>)
    );
}

export default AuthError