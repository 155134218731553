import React from "react";
import { CssBaseline, AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { useLocation, Link } from 'react-router-dom';
import Cookies from "js-cookie";

const Navigation = () => {
    const locationsToExclude = ['register', 'login', 'resumeBuilder', 'assessment_invite', 'account_recovery', 'reset'];
    const location = useLocation();

    const menuItems = [
        { text: 'Home', path: '../home' },
        { text: 'Resume', path: '../resume' },
        { text: 'Requests', path: '../requests' },
        { text: 'Results', path: '../results' },
    ];

    const buttonStyles = {
        textTransform: 'none',
        color: 'white',
        fontSize: 14,
        '&:hover, &:focus': {
            backgroundColor: '#3a6073',
            color: 'white',
            borderRadius: 5,
        },
    };

    const redirectToPortal = () => {
        window.location.replace("https://interview.nujob.ai/assessments");
    };

    const shouldDisplayNav = !locationsToExclude.includes(location.pathname.split('/')[1]) && location.pathname !== "/";

    return shouldDisplayNav ? (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{background: 'linear-gradient(to right, #16222a, #3a6073)'}}>
                <Toolbar>
                    <img alt={''} src={'./nujob.svg'} width={125} onClick={() => window.location.replace("https://nujob.ai")}/>
                    <Typography sx={{ fontWeight: 'bold', flexGrow: 1, fontSize: 16 }}>
                    </Typography>

                        {menuItems.map(({ text, path }) => (
                            <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                <Button sx={buttonStyles}>{text}</Button>
                            </Link>
                        ))}

                        <Button
                            onClick={redirectToPortal}
                            sx={{ ...buttonStyles, padding: '16px', transition: 'background-color 0.3s ease' }}
                            aria-label="Exam Portal"
                        >
                            Exam Portal
                        </Button>

                        <Link to="../login" style={{ textDecoration: 'none' }}>
                            <Button sx={buttonStyles}>Logout</Button>
                        </Link>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Box>
    ) : null;
};

export default Navigation;
