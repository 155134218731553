import React from "react";

export const InviteError = () => {

    return (

        (<>
            <div className="futuristic-error-notification">
                <span className="futuristic-error-message">Your link has expired or reuqest doesnt exist</span>
            </div>
        </>)
    );
}

export default InviteError