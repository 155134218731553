export const leaderboards = '/cp/api/v1/leaderboards'
export const profile = '/cp/api/v1/profiles'
export const assessmentRequests = '/cp/api/v1/assessment_request'
export const postAssessmentRequests = '/cp/api/v1/assessment_request'
export const jobBoard = '/cp/api/v1/job_board'
export const results = '/cp/api/v1/results'
export const auth = '/auth'
export const resume = '/cp/api/v1/resume'
export const resumeUpload = '/cp/api/v1/resume'
export const userRegister = '/cp/api/v1/register'
export const keywordAssessment = '/cp/api/v1/keyword_assessment'
export const appliedJobs = '/cp/api/v1/applied_jobs'
export const transcribe = "/resume_builder/api/v1/transcribe"
export const prepareInterview = "/resume_builder/api/v1/prepare_interview"
export const buildResume = "/resume_builder/api/v1/build_resume"
export const updateResume = "/cp/api/v1/update_resume"
export const imageUrl = "/cp/api/v1/image"
export const keywordRequests = "/cp/api/v1/keyword_requests/"
export const resumeDownload = "/stg/api/v1/resume"
export const updateProfile = "/cp/api/v1/update_profile"
export const recovery = "/account_recovery"
export const passwordReset = "/password_reset"



