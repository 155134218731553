import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useFetch from "../../../hooks/useFetch";
import { assessmentRequests } from "../../../hooks/urls";
import LoadingPage from "../../components/statuses/pageLoader";
import InviteError from "../../components/statuses/inviteError";
import AuthError from "../../components/statuses/authError";

const REGISTER_PATH = "/register";

const AssessmentInvite = () => {
    const location = useLocation();
    const inviteKey = location.pathname.split('/').at(-1);
    const apiUrl = `${assessmentRequests}?inviteKey=${inviteKey}`;

    const [{ response, error, isLoading, expired }, fetchAssessment] = useFetch(apiUrl, 'get');

    useEffect(() => {
        if (!response) {
            fetchAssessment();
        }
    }, [response, fetchAssessment]);

    // Extract conditional rendering into functions
    const renderRedirect = () => response?.invite && <Navigate push to={{ pathname: REGISTER_PATH }} from={location.pathname} />;
    const renderInviteError = () => !response?.invite && <InviteError />;
    const renderLoading = () => isLoading && <LoadingPage />;
    const renderAuthError = () => expired && <AuthError />;

    return (
        <div className={'stacked-container-vertical'}>
            {response ? (renderRedirect() ?? renderInviteError()) : null}
            {renderLoading()}
            {renderAuthError()}
        </div>
    );
};

export default AssessmentInvite;