import React from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import LoadingPage from "./pages/components/statuses/newLoader";
import Navigation from "./pages/components/nav";
import AssessmentInvite from "./pages/userRegister/assessment_invite";

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                if (retriesLeft === 1) {
                    reject(error);
                    return;
                }
                setTimeout(() => {
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

const Index = React.lazy(()=> retry(() => import("./pages/index")));
const UserAuth = React.lazy(()=> retry(() => import("./pages/login")));
const RegisterUser = React.lazy(()=> retry(() => import("./pages/userRegister")));
const ResumeIndex = React.lazy(()=> retry(() => import("./pages/resume")));
const RequestsInbox = React.lazy(()=> retry(() => import("./pages/requests")));
const ResultsInbox = React.lazy(()=> retry(() => import("./pages/results")));
const InterviewIndex = React.lazy(()=> retry(() => import("./pages/resumeBuilder")));
const RequestDetails = React.lazy(()=> retry(() => import("./pages/requests/components/requestDetails")));
const AccountRecovery = React.lazy(()=> retry(() => import("./pages/login/components/passwordReset")));
const NewPassword = React.lazy(()=> retry(() => import("./pages/login/components/passwordLastStep")));

function App() {
    return (
        <div className="App">
            <React.Suspense fallback={<LoadingPage secondary={'Loading your content, please wait...'}/>}>

                <Router>
                    <Navigation/>
                    <Routes>

                        <Route path="/home" element={
                            <Index/>
                        }/>

                        <Route path="/results" element={
                            <ResultsInbox/>
                        }/>

                        <Route path="/requests" element={
                            <RequestsInbox/>
                        }/>

                        <Route path="/requests/:id" element={
                            <RequestDetails/>
                        }/>

                        <Route path="/resume" element={
                            <ResumeIndex/>
                        }/>

                        <Route path="/resumeBuilder" element={
                            <InterviewIndex/>
                        }/>
                        <Route path="/exam" element={
                            <UserAuth/>
                        }/>

                        <Route path="/register" element={
                            <RegisterUser/>
                        }/>
                        <Route path="/login" element={
                            <UserAuth/>
                        }/>
                        <Route path="/assessment_invite/:invite_key" element={
                            <AssessmentInvite/>
                        }/>
                        <Route path="/"
                               element={<Navigate to="/login"/>}
                        />
                        <Route path="/account_recovery"
                               element={<AccountRecovery to="/account_recovery"/>}
                        />

                        <Route path="/reset/:reset_key"
                               element={<NewPassword/>}
                        />

                    </Routes>
                </Router>
            </React.Suspense>

        </div>
    );
}

export default App;
