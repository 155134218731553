import React from 'react';
import {Box, Typography} from '@mui/material';

const getBoxStyles = () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
});

const primaryTextStyle = {
    fontSize: '16px',
    textAlign: 'center',
};

const secondaryTextStyle = {
    fontSize: '16px',
    textAlign: 'center',
    mt: 1,
    color: '#cccccc'
};

const LoadingPage = ({primary, secondary}) => {
    return (
        <Box sx={getBoxStyles()} className={'loading-container'}>
            <div className={'stacked-container-vertical'} style={{gap: '5px'}}>
                <Typography sx={primaryTextStyle}>
                    {primary}
                </Typography>
                <Typography sx={secondaryTextStyle}>
                    {secondary}
                </Typography>
                <div className="progress-container">
                    <div className="progress-bar animate"></div>
                </div>
            </div>
        </Box>
    );
}

export default LoadingPage;