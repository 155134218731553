import React from 'react';
import {Box, Typography} from '@mui/material';
import {keyframes} from '@emotion/react';
import Stack from "@mui/material/Stack";

const backgroundAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const LoadingPage = ({primary, secondary}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '500px',
                animation: `${backgroundAnimation} 15s ease infinite`,
                color: 'white'
            }}
        >
            <Stack spacing={1}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    {primary}
                </Typography>

                {secondary && (
                    <>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '14px',
                                textAlign: 'center',
                                mt: 1,
                                color: '#cccccc'
                            }}
                        >
                            {secondary}
                        </Typography>

                    </>
                )
                }

                <div className="progress-container">
                    <div className="progress-bar animate"></div>
                </div>
            </Stack>
        </Box>
    );
}

export default LoadingPage;
