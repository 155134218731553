import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

const useFetch = (url, method, data, headers) => {

    const [responseCode, setResponseCode] = useState(null);
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expired, setExpired] = useState(null);

    useEffect(() => {
        let mounted = true;

        if (!isLoading) {
            return;
        }

        const fetchData = async () => {

            try {

                const res = await axios({
                    method: method,
                    url: url,
                    data: data !== undefined ? data : {},
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'),
                        "Authorization": `Bearer ${Cookies.get('token')}`
                    },
                    withCredentials: true,
                });

                if (mounted) {
                    setResponse(res.data);
                    setResponseCode(res.status)
                    setIsLoading(false);
                }

            } catch (err) {
                if (mounted) {
                    let errorMessage = 'Something went wrong';
                    if (err.response) {
                        if (err.response.status === 401) {
                            setExpired(true)
                        } else {
                            setError(true)
                        }
                    }

                    setError(errorMessage);
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => (mounted = false);
    }, [isLoading, url, method, data, headers]);

    const doFetch = useCallback(() => {
        setIsLoading(true);
        setError(null);
        setResponse(null);
        setExpired(null)
    }, []);

    return [{ response, error, isLoading, expired, responseCode }, doFetch];
};

export default useFetch;
